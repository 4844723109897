<template>
  <div class="mw8 center">
    <h1>
      Request Access to IAJESdb
    </h1>
    <p class="pa2 bg-light-yellow b">
      IAJESdb is only available for pre-approved users. You can request access
      using the form below.
    </p>
    <label for="user-email" class="b mv2 dib">User Email</label>
    <p class="i">
      This is the email address used to manage your account. This address is not
      published. You can choose a different email to share with other IAJESdb
      users.
    </p>
    <div v-show="$v.name.$error" class="red b f6">
      Name requires 4 or more characters.
    </div>
    <input
      id="user-name"
      placeholder="Your name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUserName($event.target.value)"
    />
    <div v-show="$v.email.$error" class="red b f6">
      Please enter a valid email address.
    </div>
    <input
      id="user-email"
      v-model.trim="email"
      placeholder="user email..."
      type="text"
      class="pa2 w-100 mv3"
      disabled
    />
    <label for="user-id" class="b mv2 dib">User ID</label>
    <p class="i">
      This code is required to complete registration. It is unique to you.
    </p>
    <div v-show="$v.email.$error" class="red b f6">
      Please enter a valid email address.
    </div>
    <input
      id="user-id"
      v-model.trim="id"
      placeholder="user id..."
      type="text"
      class="pa2 w-100 mv3"
      disabled
    />
    <div
      v-if="!$v.$invalid"
      class="pa2 b-grey bw1 ba br1 dim pointer dib mv3"
      @click="saveChanges()"
    >
      Request Access
    </div>
    <div
      v-show="$v.$invalid"
      id="saveButton"
      class="pa2 b-grey bw1 ba br1 o-80 dib mv3"
    >
      Cannot Request Access Due to Errors
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      name: '',
      email: '',
      id: ''
    }
  },
  computed: {
    ...mapState('requests', ['requestNameToCreate', 'requestEmailToCreate'])
  },
  created() {
    this.email = this.$attrs.email
    this.id = this.$attrs.id
  },
  methods: {
    ...mapMutations('requests', [
      'setRequestNameToCreate',
      'setRequestEmailToCreate'
    ]),
    ...mapActions('requests', ['triggerAddRequestAction']),
    ...mapActions('authentication', ['logout']),
    setUserName(val) {
      this.name = val
      this.setRequestNameToCreate(val)
      this.$v.name.$touch()
    },
    async saveChanges() {
      this.setRequestEmailToCreate(this.email)
      this.triggerAddRequestAction({ id: this.id })
      this.logout()
      this.$router.push({
        name: 'pending-approval'
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped></style>
